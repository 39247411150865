import { connect } from 'react-redux';

import {
    CmsPageContainer as SourceCmsPageContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/CmsPage/CmsPage.container';

import { has } from 'lodash';
import { Page } from 'Component/Header/Header.config';
import history from 'Util/History';
import { debounce } from 'Util/Request/Debounce';
import { getUrlParam, isHomePageUrl } from 'Util/Url';

import { LOADING_TIME } from './CmsPage.config';
import agtm from './../../gtm/tags';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Route/CmsPage/Container */
export class CmsPageContainer extends SourceCmsPageContainer {
    // TODO implement logic

    onPageLoad() {
        const {
            updateMeta,
            setHeaderState,
            updateBreadcrumbs,
        } = this.props;
        const { cmsPage } = this.props;
        const { location: { pathname } } = history;
        const {
            content_heading,
            meta_title,
            title,
            meta_description,
            meta_keywords,
        } = cmsPage;

        // console.log("CmsPage: ", cmsPage);
        
        debounce(this.setOfflineNoticeSize, LOADING_TIME)();

        updateBreadcrumbs(cmsPage);
        updateMeta({
            title: meta_title || title,
            description: meta_description,
            keywords: meta_keywords,
            canonical_url: window.location.href,
        });

        if (!isHomePageUrl(pathname)) {
            setHeaderState({
                name: Page.CMS_PAGE,
                title: title, // changed by sanyasirao
                onBackClick: () => history.goBack(),
            });
        }

        const data = {
            'Landing Page Slug': has(cmsPage, 'url_key') ? cmsPage.url_key : '',
            'Landing Page ID': has(cmsPage, 'page_id') ? cmsPage.page_id : '',
            'Landing Page Name': has(cmsPage, 'title') ? cmsPage.title : '',
            'Category Name': has(cmsPage, 'title') ? cmsPage.title : '',
            'Category Id': '',
            'Referrer Page': document?.referrer || window.location.href,
            'URL': window?.location.href || '',
        };

        const date_time = `${Math.floor(new Date() / 1000)}`;
        data['date_time'] = date_time;
        const contentCategory = data['Landing Page Slug'];
        const contentName = data['Landing Page Name'];
        const contentType = 'product_group';
        const contentIds = [];
        const contents = [];
        let itemsArr = [];
        let itemsBannerArr = [];

        agtm.triggerLandingPageView(
            data,
            itemsArr,
            cmsPage.title,
            'Landing Page',
            itemsBannerArr,
            { date_time, contentIds, contents, contentType, contentName, contentCategory }
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
